const cmtId = 's26';
const toolToken = 'tool_ga';
let gaLoaded = false;
let gaId = '';
export class Analytics {

  load() {

    //Title
    switch (window.location.hostname) {
      case "www.mannheimer-morgen.de":
        gaId = 'UA-178661217-1';
        break;
      case "www.fnweb.de":
        gaId = 'UA-178661217-4';
        break;
      case "www.schwetzinger-zeitung.de":
        gaId = 'UA-178661217-2';
        break;
      case "www.bergstraesser-anzeiger.de":
        gaId = 'UA-178661217-3';
        break;
      default:
        gaId = 'UA-178661217-1';
        break;
    }

    window.tp = window.tp || [];

    tp.push(["init", function () {
      tp.setGA4Config({
        measurementId: gaId,
        eventParameters: {
          send_page_view: true,
          page_location: window.location.href,
          page_title: document.title
        }
      });
    }]);
  }

  trackingPageView() {
    this.tracker('pageView', window.location.href);
  }

  getCmtId() {
    return cmtId;
  }

  getToolToken() {
    return toolToken;
  }

  isLoaded(){
    return gaLoaded;
  }

  destroy(){
    window.tp = window.tp || [];

    tp.push(["init", function () {
      tp.setGA4Config({
        measurementId: '',
        eventParameters: {
        }
      });
    }]);

    const cookieNames = ['_ga', '_gid', '_gat', '_AMP_TOKEN']
    window.ga = {};
    gaId.forEach( (item) => {
      window['ga-disable-'+item] = true;
    });
    cookieNames.forEach( (cookieName) => {
      this.destroyCookie(cookieName);
    });
    gaLoaded = false;
  }

  destroyCookie(cookieName) {
    let domain = document.location.hostname.replace(/^[^.]+/g, "");
    let expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT"
    document.cookie = cookieName + "='';" + expires + ";domain="+domain+";path=/;";
    return true;
  }
}
